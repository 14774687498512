import React, { useEffect, useState, createContext } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navigation, { Link } from './features/navigation/Navigation';
import Logo from './features/logo';
import { supabase, useCategoriesQuery, useDepartmentsQuery, useLangQuery, useLinksQuery, 
    usePeopleQuery, useProjectsQuery } from './app/api';
import { Projectlist } from './features/project/projectlist';
import { People } from './features/people/people';
import { NotFound } from './pages/notfound';
import { About, about_ids } from './pages/about';
import { useDispatch, useSelector } from 'react-redux';
import Auth from './app/Auth';
import Account from './app/Account';
import { Home } from './home';
import { path, toUpper} from 'ramda';
import { menu_open, set_language, show_info } from './app/ui_state';
import { Footer } from './features/footer/footer';
import { GraphOutlet } from './features/with_graph/graph_outlet';
import { Jobs } from './pages/jobs';
import { Categories } from './features/categories/categories';
import { News } from './pages/news';
import { Search } from './features/search';
import { Power, power_ids } from './pages/power';
import { Eurostudent, eurostudent_ids } from './pages/eurostudent';
import { Privacy } from './pages/privacy';
import { load_timeline, load_url, resize } from './app/load_actions';
import CookieConsent, {getCookieConsentValue,Cookies} from "react-cookie-consent"; 
import ReactGA from "react-ga4";
/* import { load_links } from './app/links_slice';
import { load_projects } from './app/projects_slice';
import { load_categories } from './app/categories_slice';
import { load_people } from './app/people_slice'; */

export const UserContext = createContext();

const App = () => {
  const language = useSelector(path(["ui","language"]));
  const {data:translation} = useLangQuery(language);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleAccept = () => {ReactGA.initialize('G-M4V9548GKR')}
  const handleDecline = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {handleAccept();}
  }, []);


  useEffect(() => {
    const langMatch = location.pathname.split("/")[1].match(/^[a-zA-Z]{2}$/);
    dispatch(set_language(langMatch ? toUpper(langMatch[0]) : "ET"));
  }, [location, dispatch]);
  
  const [session, setSession] = useState(null);
  const menu_opened = useSelector(path(["ui", "menu_open"]));
  const info_open = useSelector(path(["ui", "show_info"]));
  const selected = useSelector(path(["ui", "selected"]));
  const {data: departments} = useDepartmentsQuery();
  const {data: projects} = useProjectsQuery();
  const {data: links} = useLinksQuery();
  const {data:categories} = useCategoriesQuery();
  const {data:people} = usePeopleQuery();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => { setSession(session) });
    supabase.auth.onAuthStateChange((_event, session) => {setSession(session) });
    dispatch(resize());
    window.addEventListener('resize', () => { dispatch(resize()) });
    if (projects) dispatch(load_timeline(projects));
  },[dispatch,projects,links])

  useEffect(() => {
/*     if (projects) dispatch(load_projects(projects));
    if (categories) dispatch(load_categories(categories));
    if (people) dispatch(load_people(people));
    if (links) dispatch(load_links(links)); */
    if (people && categories && projects && departments) {
      dispatch(load_url({people,categories,projects,departments,prev_state:location.state}));
    }
  }, [dispatch,people,categories,projects,departments,location]);

  useEffect(() => {
    if(translation) {
      const headings = {
        'tood':translation["works"],
        'inimesed':translation["people"],
        'teemad':translation["topics"],
        'uudised': translation["news"],
        'kandideeri': translation["join"],
        'praxisest': translation["about"],
      };

      const loc = location.pathname.split("/")[language === "EN" ? 2 : 1];
      document.title = location.pathname !== "/" ? 
        `${(["tood","inimesed","teemad"].includes(loc) && selected) ? 
          selected[
            "inimesed" === loc ? "name" : 
            "tood" === loc ? ((language === "EN" && selected.projectname_eng) ? "projectname_eng" : "name") :
            ((language === "EN" && selected.name_eng) ? "name_eng" : "name")
        ] 
        : (headings[loc] || loc)} | Praxis` : 
        "Mõttekoda | Praxis"
    }
  }, [location,selected,language,translation]);

  const new_lang_link = language === "ET" ? `/en${location.pathname}`: 
    location.pathname.split("/").slice(2,Infinity).join("/");

  return !translation ? null : (
    <div>
      <UserContext.Provider value={session}>
        <Navigation />  
        <header className={menu_opened ? "opened" : ""} onClick={x => { x.stopPropagation(); dispatch(menu_open()) }}>
          <Logo color={"white"} height={30} />
          <span className="title">{translation["name"]}</span>
          <span className="menutoggle" onClick={x => {x.stopPropagation(); dispatch(show_info())}}>
            {(info_open ? "‹ " + translation["menu"] : translation["contacts"] + " ›")}
          </span>
        </header>
        <main id="main">
          <Routes>
          <Route path="/en">
              <Route index exact element={<Home />} />
              <Route path="praxisest" element={<About {...about_ids[language]} />} />
              <Route path="konto" element={!session ? <Auth /> : <Account key={session.user.id} session={session} />} />
              <Route path="kandideeri" element={<Jobs />} />
              <Route path="power" element={<Power {...power_ids[language]}/>} />
              <Route path="eurostudent" element={<Eurostudent {...eurostudent_ids["ENG"]}/>} />
              <Route path="privaatsus" element={<Privacy />} />
              <Route path="tood" element={<GraphOutlet type="tood" />}>
                <Route index element={<Projectlist />} />
                <Route path=":slug">
                  <Route index element={<Projectlist />} />
                  <Route path=":slug" element={<Projectlist />} />
                </Route>
              </Route>
              <Route path="inimesed" element={<GraphOutlet type="inimesed" />}>
                <Route index element={<People />} />
                <Route path=":slug" element={<People />} />
              </Route>
              <Route path="teemad" element={<GraphOutlet type="teemad" />}>
                <Route index element={<Categories />} />
                <Route path=":slug" element={<Categories />} />
              </Route>
              <Route path="uudised">
                <Route index element={<News />} />
                <Route path=":slug" element={<News />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route index element={<Home />} />
            <Route path="/praxisest" element={<About {...about_ids[language]} />} />
            <Route path="/konto" element={!session ? <Auth /> : <Account key={session.user.id} session={session} />} />
            <Route path="/kandideeri" element={<Jobs />} />
            <Route path="/power" element={<Power {...power_ids[language]}/>} />
            <Route path="/eurostudent" element={<Eurostudent {...eurostudent_ids["EST"]}/>} />
            <Route path="/privaatsus" element={<Privacy />} />
            <Route path="/tood" element={<GraphOutlet type="tood" />}>
              <Route index element={<Projectlist />} />
              <Route path=":slug">
                <Route index element={<Projectlist />} />
                <Route path=":slug" element={<Projectlist />} />
              </Route>
            </Route>
            <Route path="/inimesed" element={<GraphOutlet type="inimesed" />}>
              <Route index element={<People />} />
              <Route path=":slug" element={<People />} />
            </Route>
            <Route path="/teemad" element={<GraphOutlet type="teemad" />}>
              <Route index element={<Categories />} />
              <Route path=":slug" element={<Categories />} />
            </Route>
            <Route path="/uudised">
              <Route index element={<News />} />
              <Route path=":slug" element={<News />} />
            </Route>
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </main>
        <Link to={new_lang_link} id="language" text={language === "EN" ? "ET" : "EN"}/>
        <Search/>
        <Footer />
        <CookieConsent 
          enableDeclineButton
          onAccept={handleAccept}
          onDecline={handleDecline}
          buttonClasses="consent-btn"
          declineButtonClasses="decline-btn"
          containerClasses="consent"
          contentClasses="consent-text"
          disableStyles={true}
          buttonText={translation["cookies_accept"]}
          declineButtonText={translation["cookies_decline"]} 
        >{translation["cookie_consent"]} 
        </CookieConsent>
      </UserContext.Provider>
    </div>
  );
}

export default App;
