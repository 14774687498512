import React from 'react';
import { Part } from './part';
import "./eurostudent.css";
import { addIndex, map as m } from 'ramda';
import Slider from 'react-slick';
import { Empty } from './swiper';


export const eurostudent_ids = {
    EST: {
        title:94,
        text_ids:[54,60,61,62],
        waves:[59,58,57,56,55],
        logos: 84
    },
    ENG: {
        title:95,
        text_ids:[90,91,92,93],
        waves:[100,99,98,97,96],
        logos: 101
    },
} 

export const Eurostudent = ({title,text_ids,waves,logos}) => {
    
    const settings = {
        customPaging: (n) => 
            <a><Part part_id={waves[n]}
                className="wave"
                key={"wave_dot_"+waves[n]} 
                components={{
                    p: ({children}) => null
            }}/></a>,
        dots: true,
        dotsClass: "wave_selectors",
        infinite: false,    
        speed: 300,
        initialSlide: 1,
        slidesToShow: 1,
        adaptiveHeight: true,
        slidesToScroll: 1,
        arrows: true,
    }
    return <>            
        <Part part_id={title} components={{p: ({children}) => <h1 class="eust-title">{children}</h1>}}/>
        <div className="eust-intro introduction">
            <div>
                <Part part_id={text_ids[0]}/>
                <Part part_id={text_ids[1]}/>
            </div>
            <div>
                <Part part_id={text_ids[2]} className="consortium"/>
            </div>
        </div>
        <div id="publications">
{/*             <h2>Rahvusvaheliste uuringute aruanded</h2> */}
            <Slider {...settings}>
                {addIndex(m)((id,n) => <>
                    <Part 
                        part_id={id}
                        className="wave"
                        key={"wave_"+id} 
                        components={{
                            h3: Empty,
                            a: ({href,children}) => 
                                <a className="wave_link" href={href} target="_blank" rel="noopener noreferrer">
                                    <span>PDF</span>
                                    <span>{children}</span>
                                </a>
                        }}/>
                </>,waves)}
            </Slider>
        </div>
        <div className="extras">
            <Part part_id={text_ids[3]} className="eust-links"/>
            <Part part_id={logos} className="eust-logod"/>
        </div>
    </>
}